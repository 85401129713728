import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET_USERS } from "@/graphql/admin/user";
import debounce from "debounce";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";
import dayjs from "dayjs";
export default {
  created: function created() {
    document.title = "\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14 | ".concat(WEBSITE);
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E43\u0E19\u0E23\u0E30\u0E1A\u0E1A\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14"
    }]);
  },
  data: function data() {
    return {
      page: 1,
      pageSize: 10,
      loading: false,
      search: ""
    };
  },
  methods: {
    debounceInput: debounce(function (e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    format_datetime: function format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }

      return "-";
    },
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -1:
          return "default";

        case 0:
          return "warning";

        case 1:
          return "success";

        case 2:
          return "warning";

        case 3:
          return "success";

        default:
          return "primary";
      }
    }
  },
  apollo: {
    users: {
      query: GET_USERS,
      variables: function variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};